@font-face {
	font-family: 'Proxima';
	src: url("fonts/ProximaNova-Thin.otf");
	src: url("fonts/ProximaNova-Thin.otf") format('opentype');
	font-weight: 100;

}

@font-face {
	font-family: 'Proxima';
	src: url("fonts/ProximaNova-Light.otf");
	src: url("fonts/ProximaNova-Light.otf") format('opentype');
	font-weight: 300;

}

@font-face {
	font-family: 'Proxima';
	src: url("fonts/ProximaNova-Regular.otf");
	src: url("fonts/ProximaNova-Regular.otf") format('opentype');
	font-weight: 400;

}

@font-face {
	font-family: 'Proxima';
	src: url("fonts/ProximaNova-Semibold.otf");
	src: url("fonts/ProximaNova-Semibold.otf") format('opentype');
	font-weight: 500;

}

@font-face {
	font-family: 'Proxima';
	src: url("fonts/ProximaNova-Bold.otf");
	src: url("fonts/ProximaNova-Bold.otf") format('opentype');
	font-weight: 600;

}

@font-face {
	font-family: 'Proxima';
	src: url("fonts/ProximaNova-Extrabold.otf");
	src: url("fonts/ProximaNova-Extrabold.otf") format('opentype');
	font-weight: 700;
}


@font-face {
	font-family: 'Proxima';
	src: url("fonts/ProximaNova-Black.otf");
	src: url("fonts/ProximaNova-Black.otf") format('opentype');
	font-weight: 900;
}
