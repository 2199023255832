@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?7g22nn');
  src:  url('fonts/icomoon.eot?7g22nn#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?7g22nn') format('truetype'),
    url('fonts/icomoon.woff?7g22nn') format('woff'),
    url('fonts/icomoon.svg?7g22nn#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-yt:before {
  content: "\e90b";
}
.icon-i-view:before {
  content: "\e90a";
}
.icon-wss:before {
  content: "\e900";
}
.icon-fb:before {
  content: "\e901";
}
.icon-ig:before {
  content: "\e902";
}
.icon-home:before {
  content: "\e903";
}
.icon-in:before {
  content: "\e904";
}
.icon-phone:before {
  content: "\e905";
}
.icon-searcher:before {
  content: "\e906";
}
.icon-marker:before {
  content: "\e907";
}
.icon-logo:before {
  content: "\e908";
}
.icon-tg:before {
  content: "\e909";
}
