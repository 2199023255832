@media screen and (max-width: 1600px ){
	.callback-btn {
		right: 15px;
		bottom: 25px;
	}

	.callback {
		right: 15px;
		bottom: 90px;
	}
}

@media screen and (min-width: 1201px ){

	.nav-search__nav > ul > li:hover > .sub-menu {
		display: block;
	}
}
@media screen and (max-width: 1200px ){
	.catalog-layout__pct {
		height: 214px;
	}

	.nav-toggle {
		display: flex;
		position: relative;
		z-index: 2;
		flex-wrap: wrap;
		align-content: space-between;
		order: 1;
		width: 36px;
		height: 32px;
		padding: 10px;
		cursor: pointer;
	}
	.nav-search.active .nav-search__search {
		opacity: 0;
	}

	.nav-search {
		position: relative;
	}
	.nav-search:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 40px;
		transition: .3s;
		opacity: 0;
		background-color: #0258aa;
		z-index: 1;
	}

	.nav-search.active:after {
		opacity: 1;
	}

	.nav-toggle.active span {
		width: 50%;
	}
	.nav-toggle.active span:first-child {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(-45deg);
	}

	.nav-toggle.active span:nth-child(2) {
		opacity: 0;
	}

	.nav-toggle.active span:last-child {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(45deg);
	}

	.nav-toggle:after {
		content: 'menu';
		display: block;
		position: absolute;
		top: 50%;
		right: -40px;
		transform: translate(100%, -50%);
		color: #fff;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: .02em;
		text-transform: uppercase;
	}

	.icon-home {
		position: relative;
	}

	.icon-home:after {
		content: 'home page';
		display: block;
		position: absolute;
		bottom: -3px;
		right: -15px;
		transform: translateX(100%);
		color: #fff;
		font-family: Proxima, sans-serif;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: .02em;
		text-transform: uppercase;
		white-space: nowrap;
	}

	.nav-toggle span {
		display: block;
		width: 100%;
		height: 2px;
		background-color: #fff;
	}

	.nav-search__nav {
		order: 2;
	}

	.nav-search__search {
		order: 1;
	}

	.nav-search__nav {
		display: none;
		width: 100%;
	}

	.nav-search__nav > ul > li {
		display: block;
		width: 100%;
		margin: 0;
	}

	.nav-search__nav > ul > li:not(:last-child) {
		border-bottom: 1px solid #92C8FC;
	}

	.nav-search__nav > ul > li.active:before {
		content: none;
	}

	.page-content {
		padding-right: 20px;
		padding-left: 20px;
		padding-bottom: 40px;
	}

	.header,
	.nav-search {
		padding-right: 5px;
		padding-left: 0;
	}

	.sidebar {
		width: 160px;
	}

	.fix-scroll.fixed {
		width: 160px;
	}

	.sidebar + main {
		width: calc(100% - 190px);
	}

	.accordion__title {
		padding-top: 7px;
		padding-bottom: 7px;
		line-height: 24px;
	}

	.accordion__title:before {
		top: 18px;
	}

	.catalog-layout__pct {
		height: 110px;
		overflow: hidden;
	}

	.catalog-layout__pct img {
		width: 100%;
		height: auto;
	}

	.catalog-layout__info {
		height: calc(100% - 110px);
	}

	.catalog {
		margin-bottom: 50px;
	}

	.footer-contacts__item {
		margin-bottom: 8px;
	}

	.footer-contacts__item:nth-child(2) {
		margin-bottom: 0;
	}

	.footer-contacts__socials > * {
		margin-right: 26px;
	}

	.footer-contacts__socials i {
		display: block;
	}

	.wss {
		font-size: 20px;
		margin: 0 0 0 auto;
	}

	.wss i {
		display: block;
	}

	.footer-contacts__socials > *:last-child {
		margin-right: 0;
	}

	.footer {
		padding: 20px 5px 40px;
	}

	.callback-btn {
		right: 20px;
	}

	.header__motto {
		width: 400px;
		font-size: 16px;
		line-height: 16px;
	}

	.header__motto:after {
		height: 113px;
	}

	.header__motto span {
		display: block;
		width: 290px;
	}

	.header {
		position: relative;
	}

	.header__top > .flex-container {
		align-content: center;
		align-items: center;
	}

	.header__phone {
		position: absolute;
		right: 0;
		bottom: 10px;
	}
}

@media screen and (max-width: 1023px) {
	.callback__form button {
		right: -2px;

	}

	.article .inline-img-wrap {
		grid-template-columns: repeat(2, 1fr);
	}

	.categories-grid {
		grid-template-columns: repeat(3, 1fr);
	}

	.partners__grid {
		grid-template-columns: repeat(3, 1fr);
	}

	.footer form button {
		padding: 0 9px;
		text-align: center;
		right: -2px;
	}

	.videos__grid {
		grid-template-columns: repeat(2, 1fr);
	}

	.contacts__map {
		width: 100%;
		margin-bottom: 30px;
	}

	.contacts__info {
		width: 100%;
	}

	.contacts__form form {
		grid-template-columns: repeat(1, 1fr);
	}

	.product__pct {
		width: 100%;
		order: 1;
		margin-bottom: 30px;
	}

	.slick-slide img {
		margin: 0 auto;
	}

	.product__info {
		width: 100%;
		order: 2;
	}

	.contacts__form form {
		grid-row-gap: 30px;
	}
}

@media screen and (max-width: 767px ){

	.contacts__form form {
		grid-row-gap: 20px;
	}

	.tabs__nav {
		display: flex;
		overflow-x: scroll;
	}

	.article__content h1 {
		font-size: 22px;
	}

	.article__content h2,
	.article__content h3 {
		font-size: 20px;
	}

	.contacts__form form {
		grid-template-columns: repeat(1, 1fr);
	}

	.textarea-wrap {
		grid-column: span 1;
	}

	.partners__grid {
		grid-template-columns: repeat(1, 1fr);
	}

	.videos__grid {
		grid-template-columns: repeat(1, 1fr);
	}

	.videos__pct img {
		width: 100%;
	}

	.categories-grid {
		grid-template-columns: repeat(1, 1fr);
	}

	.categories__pct img {
		width: 100%;
	}

	.article .inline-img-wrap {
		grid-template-columns: repeat(1, 1fr);
	}

	.article__content ul li {
		padding-left: 35px;
	}

	.article__content ul li:before {
		left: 12px;
	}

	.callback__form {
		width: 225px;
	}

	.callback__form input {
		padding: 0 70px 0 10px;
		font-size: 12px;
	}

	.sidebar {
		width: 100%;
		margin-bottom: 25px;
	}

	.sidebar + main {
		width: 100%;
	}

	.sidebar .accordion {
		display: none;
	}

	.sidebar__title {
		position: relative;
	}

	.sidebar__title {
		cursor: pointer;
	}

	.sidebar__title:before {
		content: '\e907';
		position: absolute;
		top: 50%;
		right: 15px;
		transform: translateY(-50%);
		-webkit-transition: .3s;
		transition: .3s;
		color: #0258AA;
		font-family: 'icomoon', sans-serif;
		font-size: 6px;
	}

	.sidebar__title.active:before {
		transform: translateY(-50%) rotate(180deg);
	}

	.catalog-grid {
		grid-template-columns: 100%;
	}

	.catalog .pagination {
		grid-column: span 1;
	}

	.footer-grid {
		grid-column-gap: 0;
		grid-row-gap: 20px;
	}

	.footer-grid > *:first-child,
	.footer-grid > *:nth-child(2) {
		grid-column: span 6;
	}

	.footer-grid > *:nth-child(3),
	.footer-grid > *:nth-child(4) {
		grid-column: span 12;
	}

	.wss {
		margin: 0 auto;
	}

	.footer-contacts__socials {
		display: table;
		width: auto;
		margin: 25px auto 35px;
	}

	.header__logo {
		font-size: 22px;
	}

	.header__motto:after {
		content: none;
	}

	.header__phone {
		display: none;
	}

	.header__motto {
		font-size: 12px;
		line-height: 12px;
	}

	.header__motto span {
		width: 101%;
	}

	.header__motto {
		width: 145px;
	}

	.header__logo {
		margin-right: 20px;
	}

	.header__top {
		padding: 5px 0;
	}

	.header {
		background-image: url('../img/head-img.png');
		background-size: cover;
	}

	.nav-toggle:after {
		right: -15px;
	}

	.header, .nav-search {
		padding: 0;
	}

	.header__language {
		position: fixed;
		top: 46px;
		right: 20px;
		z-index: 9999;
		opacity: 0;
		transition: .3s;
	}

	.header__language.active {
		opacity: 1;
	}

	body.scrolled .header__language {
		top: 0;
	}

	.nav-search .sub-menu {
		position: relative;
		background-color: transparent;
	}

	.nav-search .has-child:after {
		position: absolute;
		right: 0;
		top: 15px;
		height: 10px;
		transform: translate(0, 0);
		line-height: 10px;
	}

	.nav-search .has-child.active:after {
		transform: translate(0, 0) rotate(180deg);
	}

	.catalog-title {
		font-size: 24px;
	}

	.main-news {
		margin-bottom: 50px;
	}

	.catalog-layout__pct {
		position: relative;
		width: 100%;
		height: 180px;
	}

	.catalog-layout__pct img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.footer-contacts__socials {
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.wss {
		text-align: center;
	}

	.not-found {
		height: auto;
	}

	.callback__form button {
		top: 36px;
	}

	.callback.error .callback__close {
		margin: 0 0 50px 15px;
	}

	.footer .copyright {
		text-align: center;
	}
}
